import React from 'react';
import { Link } from 'react-router-dom';
import '@styles/tyc.scss';

import logo from '@img/logo.svg'

const PdP = () => {

	return (
        <div className="home">
            <header className="header">
                <input className="side-menu" type="checkbox" id="side-menu"/>
                <label className="hamb" htmlFor="side-menu"><span className="hamb-line"></span></label>
                <Link to={'/'} activeClassName="current"><img src={logo} alt="contrata-logo"/></Link>
            </header>
            <div className='tycContainer'>
                <div className='tycTitle'>Política de privacidad</div>
                <div className='tyctext'>
                    <p>
                        Gestionamos un banco de datos de currículums vitae documentados (CV)
                        de profesionales de diferentes especialidades para su ubicación en un
                        puesto laboral en empresas de diferentes rubros, pero previo consentimiento
                        de su titular y bajo ambientes de transparencia y confidencialidad entre los
                        titulares de la información y nuestros clientes.
                    </p>
                    <p className='tycSubTitle'>1. Consentimiento</p>
                    <p>
                        Tu consentimiento expreso por nuestros medios digitales para almacenar tu
                        CV en nuestra base de datos es suficiente para que sea almacenado para
                        uso en un procedimiento de selección en el marco de la normativa en
                        contratación pública o uno que lleve a cabo un organismo internacional a
                        las empresas o clientes. Incluye en el consentimiento, recabar información y
                        el proceso de verificación y recabar información
                    </p>
                    <p>
                        Asimismo, al usar el sitio web usted da su consentimiento para la recolección,
                        verificación, utilización y almacenamiento de la información por Contrata
                        conforme a las políticas de privacidad.
                    </p>
                    <p className='tycSubTitle'>2. Almacenamiento</p>
                    <p>
                        Tu currículum vitae documentado incluyendo la información de tus datos
                        personales solo se gestiona o almacena en nuestra base de datos cuando
                        te pones en contacto con Contrata a través de nuestros canales
                        digitales y manifiestas como titular tu consentimiento expreso.
                    </p>
                    <p className='tycSubTitle'>3. Selección y exclusión</p>
                    <p>
                        Contrata gestiona tu currículum vitae documentado en nuestro banco de
                        datos de curriculums vitae solo cuando considera que cumple con los
                        requisitos de un currículo vitae potencial, el consentimiento es expreso y la
                        información es veraz. Y los excluye cuando se ha comprobado la falta de
                        veracidad de la información, no existe consentimiento expreso o su titular ha
                        solicitado su exclusión.
                    </p>
                    <p className='tycSubTitle'>4. Entrega de CV</p>
                    <p>
                        Tu CV será entregado a solicitud de nuestros clientes, con tu autorización o
                        consentimiento expreso y disponibilidad para ser presentado en un
                        procedimiento de selección en el marco de la normativa en contratación
                        pública o uno que lleve a cabo un organismo internacional. No se admitirá
                        consentimiento o autorización por terceras personas o intermediarios.
                    </p>
                    <p className='tycSubTitle'>5. Inicio de labores o compensación económica</p>
                    <p>
                        Tus labores están condicionadas a la obtención de la buena pro, suscripción
                        del contrato y al inicio de actividades. La compensación económica solo
                        será posible cuando se produzca la renuncia previa al inicio de la prestación
                        del servicio por un evento de fuerza mayor o caso fortuito que haga
                        imposible el inicio de labores. Esta compensación será acordada al
                        momento de efectuar tu consentimiento y autorización para el envío de tu
                        currículum vitae documentado a nuestros clientes.
                    </p>
                    <p className='tycSubTitle'>6. Confidencialidad</p>
                    <p>
                        <b>Contrata</b> se compromete a guardar reserva y confidencialidad de la
                        información proporcionada por los titulares de los CV e información que se
                        almacene en nuestro banco de datos en estricto cumplimiento de la Ley Nº
                        29733, Ley de Protección de Datos Personales o aquellos que se reciba por
                        nuestros canales digitales conforme a nuestro Términos y Condiciones.
                    </p>
                    <p>
                        Información complementaria a nuestra política de privacidad que regula y
                        rige la prestación de nuestros servicios se encuentra en los términos y
                        condiciones.
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <p>
                        Los Términos y Condiciones se actualizaron por última vez el 31 de enero de 2023.
                    </p>
                </div>
            </div>
        </div>
	);
}

export default PdP;