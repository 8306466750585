import axios from "axios";

let headers = {
    'Content-Type': 'application/json',
}

export const contactCompany = async (data) => {
    try {
        const res = await axios.post('https://rx2rxem5lk.execute-api.us-east-1.amazonaws.com/send-mail-web', data, {headers: headers});
        return res.data;
    } catch (error) {
        console.log("Error" + error)
    }
}
