import React from 'react';

const NotFound = () => {
	return (
		<div>
			No encontrado, error 404.
		</div>
	);
}

export default NotFound;