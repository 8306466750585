import React from 'react';
import { Link } from 'react-router-dom';
import '@styles/tyc.scss';

import logo from '@img/logo.svg'

const TyC = () => {

	return (
        <div className="home">
            <header className="header">
                <input className="side-menu" type="checkbox" id="side-menu"/>
                <label className="hamb" htmlFor="side-menu"><span className="hamb-line"></span></label>
                <Link to={'/'} activeClassName="current"><img src={logo} alt="contrata-logo"/></Link>
            </header>
            <div className='tycContainer'>
                <div className='tycTitle'>Términos y Condiciones</div>
                <div className='tyctext'>
                    <p>
                        Aquí se detallan los términos y condiciones para la prestación de servicios para
                        profesionales y empresas. Agradecemos se de lectura detenidamente. Los
                        profesionales y empresas al suscribir los términos y condiciones del servicio, aceptan
                        libremente los enunciados y se comprometen a cumplirlos en todo su contenido.
                        De no estar de acuerdo, solicitamos expresar por correo electrónico su desafiliación
                        y se abstenga del uso de nuestros servicios.
                    </p>
                    <p>
                        En lo sucesivo, cuando se haga referencia a profesionales, serán aquellas personas
                        naturales titulares del Currículum Vitae documentado (CV) que han dado su
                        consentimiento para su almacenamiento en nuestro banco de datos personales
                        para uso en procedimientos de selección y licitaciones con el Estado y Organismos
                        Internacionales con fines estrictamente laborales o de empleo. Al hacer referencia
                        a empresas, son las personas naturales y/o jurídicas que requieren nuestros servicios
                        de entrega de CV de profesionales para los fines descritos en el párrafos
                        precedentes.
                    </p>
                    <p className='tycSubTitle'>1. Registro en la página web y envió de curriculum vitae</p>
                    <p>
                        Al efectuar su registro en la página web aportará a Contrata sus datos como
                        nombre completo, dirección de correo electrónico y su CV. Si es empresa registrará
                        sus datos personales o de su empresa. El tratamiento de esta información será
                        absolutamente confidencial. La información proporcionada como su CV solo
                        podrá trasladar a las empresas con su consentimiento y autorización y para fines
                        estrictamente laborales. Para mayor información sobre este punto, puede revisar la
                        Política de Privacidad.
                    </p>
                    <p>
                        El envío de su CV no implica aceptación en nuestro banco de datos de información
                        personal, debido a que este pasará por un filtro de veracidad que podría ocasionar
                        su exclusión, acto que será comunicada oportunamente por el mismo canal a su
                        titular. Asimismo, al enviar su CV y la información contenida en este, afirma que es
                        verídica, precisa y se ajusta a la verdad.
                    </p>
                    <p className='tycSubTitle'>2. Prohibición de uso de nuestros pervicios por menores de edad</p>
                    <p>
                        Esta prohibido el uso de nuestros servicios por menores de edad o que carezcan de
                        capacidad de ejercicio de sus derechos civiles, salvo que obtengan el permiso de
                        sus padres, tutores o curadores según la legislación aplicable. Sin perjuicio de lo
                        señalado, al enviarnos su información y su CV y al contratar nuestros servicios,
                        confirma que es mayor de edad y tiene la capacidad legal o la representación
                        legal.
                    </p>
                    <p className='tycSubTitle'>3. Garantía en la prestación de servicios y funcionamiento de la web </p>
                    <p>
                        Contrata no garantiza la disponiblidad constante y correcto funcionamiento
                        de nuestra web. Es posible que al registrar información se presenten errores que se
                        corregirán en un plazo razonable. Asimismo, no garantizamos que nuestra web este
                        libre de virus u otros daños a sus equipos.
                    </p>
                    <p>
                        Contrata cuenta con correos autorizados o el canal de nuestra web como los
                        únicos medios de atención de nuestros servicios, tanto para profesionales y
                        empresas, para enviar o recibir la información de CV almacenada en nuestro
                        banco de datos de profesionales o información de las empresas. Por ello, no se
                        hace responsable de los daños ocasionados por información enviada a otros
                        medios distintos a los acreditados en nuestro sitio web que sean usados a nombre
                        de Contrata o que bajo este medio se usen por terceros usando el nombre de
                        Contrata.
                    </p>
                    <p>
                        El tratamiento de datos personales y la información de su CV de los profesionales
                        solo será almacenada en nuestro banco de datos personales cuando se hagan
                        llegar por su titular y previa validación de su identidad, para validar su
                        consentimiento. No se tratará datos personales de los profesionales, bajo ningún
                        motivo, cuando lo haga llegar un tercero a nombre de su titular.
                    </p>
                    <p>
                        Contrata no se responsabiliza frente a empresas y profesionales, si el comité de
                        selección y órganos colegiado que revisa las propuestas u ofertas y ofertas, las
                        descalifica, precisando que los certificados o constancias de trabajo, incumplen
                        las bases o documentos de licitación respecto a una lectura literal de lo solicitado
                        y lo que se acredita, en la medida se ahonda en un criterio estrictamente subjetivo
                        que no esta a nuestro alcance. Para este tipo de cuestionamientos existen otras
                        vias.
                    </p>
                    <p>
                        Contrata no se responsabiliza frente a las empresas, si una entidad publica u
                        organismo público recibe informacion contrataria del empleador del titular del CV
                        respecto de información contenida en el CV contrario a lo comunicado a
                        Contrata.
                    </p>
                    <p className='tycSubTitle'>4. Prohibición de cesión de información personal o curriculum vitae</p>
                    <p>
                        Las empresas al recibir la información de profesionales y su CV por Contrata, lo
                        hace con el debido al consentimiento previo de su titular para uso unicamente en
                        un procedimiento de selección o licitación y se compromete a no cederlo de
                        manera parcial o total a terceras personas. Su incumplimiento habilitará a Contrata
                        las acciones legales en el marco de la Ley de Protección de Datos Personales, sin
                        perjuicio de las acciones que ejerza su titular.
                    </p>
                    <p className='tycSubTitle'>5. Exclusión o cancelación de curriculum vitae de nuestro banco de datos personal</p>
                    <p>
                        Contrata se reserva el derecho de excluir y cancelar el registro del CV de su
                        banco de datos de profesionales al demostrarse que la información contenida en
                        este, es contraria a la verdad o cuando se determine el incumplimiento de las
                        politicas de privacidad y los términos y condiciones.
                    </p>
                    <p className='tycSubTitle'>6. Garantia para el acceso de oportunidades laborales</p>
                    <p>
                        Contrata no garantiza a los profesionales que consienten el envío de su CV a
                        nuestros clientes el acceso a oportunidades laborales o una remuneración
                        determinada, por cuanto depende de factores como su uso efectivo durante el
                        procedimiento de selección en las ofertas o propuestas, la buena pro o
                        adjudicación, la sucripción de contrato y la negociacion de condiciones laborales,
                        las cuales dependen de las empresas que han contratado nuestros servicios.
                        Nuestro servicio culmina cuando se entrega el CV con el cumplimiento del perfil
                        solicitado en las bases y documentos de licitación y completamente verificado y
                        previamente aceptado por la empresa. Posterior a ello, la negociación de
                        remuenración o compensación depende de la empresa o cliente.
                    </p>
                    <p className='tycSubTitle'>7. Seguridad del banco de datos de profedsionales y de información</p>
                    <p>
                        Contrata adopta un sistema de seguridad generalmente aceptado que
                        garantiza la protección de la información personal de nuestros profesionales
                        almancenados en nuestro banco de datos personal, por el mal uso o pérdida o
                        destrucción de la información contenida en este. No obstante no garantizamos
                        que hackers vulneren nuestro sistema de seguridad.
                    </p>
                    <p className='tycSubTitle'>8. Compensación económica por uso de Curriculum Vitae</p>
                    <p>
                        Los profesionales podrán ser compensados con un concepto económico
                        razonable si luego de otorgada la buena pro o de de suscrito el contrato con la
                        entidad u organismo público y por diferentes motivos renuncia al cargo al que
                        postula por motivos de fuerza mayor o caso fortuito que hacen imposible su
                        participación en la obra o proyecto, surgido de manera sobreviniente. Esta
                        condición se pone en claro al momento de autorizar el envío de su CV a la
                        empresa. En otros casos, no existe comprensación.
                    </p>
                    <p className='tycSubTitle'>9. Modificación de los términos y condiciones</p>
                    <p>
                        Contrata se reserva el derecho de modificar de manera unilateral total o
                        parcial los presentes Términos y Condiciones en cualquier momento, la misma que
                        tendrá validez plena desde el día siguiente de haberse comunicado y notificado
                        por nuestro sitio web y correo electrónico. Cabe precisar que la comunicación por
                        nuestro sitio web o envio de correo electrónico implica conocimiento y aceptación
                        de los Términos y Condiciones.
                    </p>
                    <p>
                        La nulidad de alguna de las clausulas de los términos y condiciones no afecta la
                        validez de las otras. Estas seguiran manteniendo su vigencia plena. Asimismo, los
                        terminos y condiciones no contradicen las garantias previstar en codigo de
                        protección del consumidor.
                    </p>
                    <p className='tycSubTitle'>10. Duración</p>
                    <p>
                        Contrata de oficio puede suspender unilaterlamente las prestación de sus
                        servicios en cualquier momento dejando a salvo la resolución y solución de
                        obligaciones pendientes iniciadas antes de la decisión de suspender sus servicios.
                    </p>
                    <p className='tycSubTitle'>11. Verificación de información</p>
                    <p>
                        Las empresas no podrán trasladar a terceros el CV debidamente verificados por
                        Contrata, a favor de terceros para su uso, y con ello, adjudicarse que se
                        encuentran correctamente verificados, pues el proceso de verificación es un acto
                        propio de Contrata que le demanda recursos económicos. El caso omiso a esta
                        dispoción habilitará el inicio de acciones legales.
                    </p>
                    <p className='tycSubTitle'>12. Contrata</p>
                    <p>
                        La denominación legal que adopta Contrata es: CONTRATA SOCIEDAD
                        ANÓNIMA CERRADA, con RUC Nº 20610516913 y domicilio en Calle. Huaca media luna Mz H2 Lt 25, La Molina, lima, Perú. El correo electrónico para
                        recbar, recibir y verificar información de su CV, solicitudes y otros es info@contrata.com.pe
                    </p>
                    <p>
                        Los Términos y Condiciones se desarrolan y tienen como marco las normativa de la
                        República del Perú. Las empresas se someten ante cualquier controversía sobre los
                        presenten términos y condiciones (interpretación o cumplimiento) a conciliación,
                        arbitrajes y la vía judicial a la jursidicción de los juzgados y tribunales de Lima
                        Cercado.
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <p>
                        Los Términos y Condiciones se actualizaron por última vez el 31 de enero de 2023.
                    </p>
                </div>
            </div>
        </div>
	);
}

export default TyC;