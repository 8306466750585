import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import '@styles/home.scss';

import logo from '@img/logo.svg'
import banner from '@img/banner.svg'
import processSearch from '@img/processSearch.svg'
import processSubmit from '@img/processSubmit.svg'
import processValidation from '@img/processValidation.svg'
import cv from '@img/cv.svg'
import integrity from '@img/integrity.svg'
import responsibility from '@img/responsibility.svg'
import security from '@img/security.svg'
import logoFooter from '@img/logoFooter.svg'
import linkedin from '@img/linkedin.svg'

import { contactCompany } from '../api/index';

const Home = () => {

    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleForm = async() => {
        const response = await contactCompany( { company, email, phone, message } );
        response.status && alert("correo enviado satisfactoriamente")
        setCompany('');
        setEmail('');
        setPhone('');
        setMessage('');

    }

    const Menu = (redirect) => {
        window.location.href = redirect;
    }

	return (
        <div className="home">
            <header className="header">
                <input className="side-menu" type="checkbox" id="side-menu"/>
                <label className="hamb" htmlFor="side-menu"><span className="hamb-line"></span></label>
                <Link to={'/'} activeClassName="current"><img src={logo} alt="contrata-logo"/></Link>
                <nav id="nav">
                    <ul>
                        <li>
                            <a className="a"  href="#process">
                                <label htmlFor="side-menu" onClick={() => Menu('#process')}>
                                    ¿Qué hacemos?
                                </label>
                            </a>
                        </li>
                        <li>
                            <a className="a"  href="#cv">
                                <label htmlFor="side-menu" onClick={() => Menu('#cv')}>
                                    Déjanos tu CV
                                </label>
                            </a>
                        </li>
                        <li>
                            <a className="a"  href="#contact">
                                <label htmlFor="side-menu" onClick={() => Menu('#contact')}>
                                    Contáctanos
                                </label>
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>
            <div className="scroll-container">
                <div className="banner">
                    <img src={banner} alt="contrata-banner"/>
                    <div className= "banner-text">
                        <p className="banner-title"> Encuentra a profesionales calificados y validados</p>
                        <p className="banner-subtitle"> Te ayudamos a buscar los profesionales que tu empresa necesita</p>
                        <a href="#contact"><button>Escríbenos aquí</button></a>
                    </div>
                </div>
                <div className="process" id="process">
                    <p className="process-title">¿Cúal es nuestro proceso?</p>
                    <p className="process-subtitle">Servicio pensado en facilitar mayor competitividad a tu empresa con información íntegra y veraz.</p>
                    <div className="process-steps">
                        <div className="step">
                            <img src={processSearch} alt="contrata-process-seach"/>
                            <p className="step-title">Búsqueda</p>
                            <p className="step-text">Nuestro banco de datos de profesionales permitirá encontrar a los profesionales con el CV acorde al perfil solicitado en las bases y documentos de licitación que buscas.</p>
                        </div>
                        <div className="step">
                            <img src={processValidation} alt="contrata-process-seach"/>
                            <p className="step-title">Validación</p>
                            <p className="step-text">Los CV almacenados en nuestro banco de datos de profesionales pasan por un proceso de validación y revisión del cumplimiento de perfil solicitado.</p>
                        </div>
                        <div className="step">
                            <img src={processSubmit} alt="contrata-process-seach"/>
                            <p className="step-title">Entregamos</p>
                            <p className="step-text">Entrega de CV listos. ¡Olvídate de las preocupaciones!</p>
                        </div>
                    </div>
                </div>
                <div className="cv" id="cv">
                    <div className= "cv-text">
                        <p className="cv-title">Déjanos tu CV y encuentra tu próximo trabajo</p>
                        <p className="cv-subtitle">Aprovecha e impulsa tu carrera y se parte de nuestro banco de datos de profesionales accediendo a una amplia oferta de oportunidades laborales.</p>
                        <a href="https://forms.gle/mu3b67gchc11esbs9" target="_blank"><button>Ingresa aquí</button></a>
                    </div>
                    <img src={cv} alt="contrata-cv"/>
                </div>
                <div className="values">
                    <div className= "values-left">
                        <p className="values-title">Nuestos valores</p>
                        <p className="values-subtitle">Somos una empresa que desarrolla sus procesos internos y externos de gestión de información documental de profesionales, garantizando el consentimiento de su titular, la confidencialidad, seguridad e integridad en el proceso de validación y con responsabilidad.</p>
                    </div>
                    <div className= "values-right">
                        <div className="values-content">
                        <div className= "values-content-left">
                            <div className="values-content-circle">
                                <img src={integrity} alt="contrata-integrity"/>
                            </div>
                        </div>
                            <div className= "values-content-right">
                                <p className="values-content-title">Integridad</p>
                                <p className="values-content-text">Nuestro proceso tiene como base la transparencia y la veracidad.</p>
                            </div>
                        </div>
                        <div className="values-content">
                            <div className= "values-content-left">
                                <div className="values-content-circle">
                                    <img src={security} alt="contrata-security"/>
                                </div>
                            </div>
                            <div className= "values-content-right">
                                <p className="values-content-title">Seguridad</p>
                                <p className="values-content-text">Protegemos la información personal y documental de nuestros profesionales y clientes.</p>
                            </div>
                        </div>
                        <div className="values-content">
                            <div className= "values-content-left">
                                <div className="values-content-circle">
                                    <img src={responsibility} alt="contrata-responsibility"/>
                                </div>
                            </div>
                            <div className= "values-content-right">
                                <p className="values-content-title">Responsabilidad</p>
                                <p className="values-content-text">Te ayudamos a competir en procedimientos de selección entregándote un Cv de profesionales de manera satisfactoria.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="help">
                    <p className="help-title">Te ayudamos a buscar a los profesionales que tu empresa necesita</p>
                </div>
                <div className="contact" id="contact">
                    <div className= "contact-left">
                        <p className="contact-title">Contáctanos</p>
                        <p className="contact-subtitle">Déjanos tus datos o tu CV y te contactaremos para ofrecerte una atención más personalizada.</p>
                        <div className="contact-data">
                            <p>+51994950493</p>
                            <p>info@contrata.com.pe</p>
                        </div>
                    </div>
                    <div className= "contact-right">
                        <form>
                            <div className="contact-input">
                                <label htmlFor="company">Nombre de la empresa</label>
                                <input type="text" id="company" name="company" placeholder="tu empresa SAC" value={company} onChange={e => setCompany(e.target.value)}/>
                            </div>
                            <div className="contact-input">
                                <label htmlFor="email">Correo</label>
                                <input type="text" id="email" name="email" placeholder = "correo@tuempresa.com" value={email} onChange={e => setEmail(e.target.value)}/>
                            </div>
                            <div className="contact-input">
                                <label htmlFor="phone">Teléfono</label>
                                <input type="text" id="phone" name="phone" placeholder= "999999999" value={phone} onChange={e => setPhone(e.target.value)}/>
                            </div>
                            <div className="contact-input">
                                <label htmlFor="message">Mensaje</label>
                                <textarea type="text" id="message" name="message" placeholder="Estamos interesados en ..." value={message} onChange={e => setMessage(e.target.value)}/>
                            </div>
                        </form>
                        <button onClick={handleForm}>Enviar</button>
                    </div>
                </div>
                <div className="footer">
                    <div className="footer-content">
                        <div className="footer-img">
                            <img src={logoFooter} alt="contrata-logo"/>
                        </div>
                        <div className="footer-tc">
                                <div className="footer-detail">
                                    <p className="title">Legales</p>
                                    <p className="subtitle link"><Link to={'/tyc'} onClick={() => window.scrollTo(0, 0)}>Términos y condiciones Contrata</Link></p>
                                    <p className="subtitle link"><Link to={'/pdp'} onClick={() => window.scrollTo(0, 0)}>Políticas de privacidad Contrata</Link></p>
                                </div>
                                <div className="footer-detail">
                                    <p className="title">Contacto</p>
                                    <p className="subtitle">Servicio al cliente:</p>
                                    <p className="subtitle">+51994950493</p>
                                    <p className="subtitle">info@contrata.com.pe</p>
                                </div>
                        </div>
                    </div>
                    <div className="footer-line"></div>
                    <p className="coypright">© Contrata, Inc. 2023. We love our users!</p>
                    <a href="https://www.linkedin.com/company/contrata-contratando/" target="_blank"><img src={linkedin} alt="contrata-linkedin"/></a>
            </div>
            </div>
        </div>
	);
}

export default Home;