import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../containers/Layout';
import Home from '../pages/Home';
import TyC from '../pages/TyC';
import PdP from '../pages/PdP';
import NotFound from '../pages/NotFound';
import '../styles/global.css';

const App = () => {
	return (
		<Router>
			<Layout>
				<Routes>
					<Route exact path="/" element={<Home/>} />
					<Route exact path="/tyc" element={<TyC/>} />
					<Route exact path="/pdp" element={<PdP/>} />
					<Route path="*" element={<NotFound/>} />
				</Routes>
			</Layout>
		</Router>
	);
}

export default App;